<template>
<v-container fluid class="mt-1 mb-5">
    <div>
        <v-row>
            <v-col cols="12">
                <div class="d-flex">
                    <breadcrumb :breadcumbs="breadcumbs"></breadcrumb>
                    <notification :snackbar="snackbar"></notification>
                    <v-spacer></v-spacer>
                    <v-text-field
                    solo
                    class="border-12 mb-2"
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Search"
                    single-line
                    hide-details
                    style="max-width:400px"
                    @keyup.enter="getSearch()"
                ></v-text-field>
                </div>
            </v-col>
        </v-row>
        <v-card class="rounded-l elevation-5">
            <v-card-title>
                Data Jemaat
                <v-spacer></v-spacer>
                <v-btn
                    small
                    color="#fff"
                    class="py-5 mr-3"
                    @click="getPullData()"
                    >
                    <v-icon>mdi-cached</v-icon>
                </v-btn>
                <v-btn
                    small
                    color="#fff"
                    class="py-5 mr-3"
                    @click="[dialogfilter = true, status = true]"
                    >
                        <v-icon>mdi-filter-variant</v-icon>
                </v-btn>
                <v-btn
                    small
                    color="#005c37"
                    class="py-5 mr-3 text-white"
                    >
                        <v-icon>mdi-file-excel</v-icon>
                        <download-excel
                            class="text"
                            :fetch           = "exportExcel"
                            :fields="headersColumn"
                            :before-generate = "startDownload"
                            :before-finish   = "finishDownload">
                            Export Excel
                        </download-excel>
                </v-btn>
                <v-btn
                    small
                    color="success"
                    class="py-5"
                    @click="[dialog = true, active_flag = true]"
                    >
                        Create New
                </v-btn>
            </v-card-title>
            <v-card-text>
                <v-data-table
                    dense
                    :headers="headers"
                    :items="data_jemaat"
                    class="elevation-1 "
                    :items-per-page="20"
                    :loading="$store.state.overlay"
                    height="578"
                    fixed-header
                    :divider="true"
                    :light="true"
                    :item-class="tr_datatable"
                >
                    <template v-slot:[`item.active_flag`]="{ item }">
                        <v-switch
                            inset
                            false-value="N"
                            true-value="Y"
                            v-model="item.active_flag"
                            @change="updateStatusMenuJemaat(item)"
                            class="mt-1 mb-2"
                            hide-details="true"
                        ></v-switch>
                    </template>   
                    <template v-slot:[`item.action`]="{ item }">
                        <v-menu
                            bottom
                            left
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    dark
                                    icon
                                    v-bind="attrs"
                                    v-on="on"
                                    color="grey"
                                    class="text-center"
                                >
                                    <v-icon>mdi-dots-vertical</v-icon>
                                </v-btn>
                            </template>

                            <v-list dense>
                                <v-list-item @click="detailItem(item)">
                                    <v-list-item-icon>
                                        <v-icon>mdi-magnify</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title>Detail</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item @click="detailItemEdit(item)">
                                    <v-list-item-icon>
                                        <v-icon>mdi-pencil</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title>Edit</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>
        <v-dialog
            v-model="dialog"
            persistent
            max-width="600px"
        >
            <v-card class="p-2 rounded-l">
                <v-card-title class="text-center">
                    <v-row>
                        <v-col cols="12">
                            Create Data Jemaat
                        </v-col>
                        <v-divider></v-divider>
                    </v-row>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row class="mt-2 mb-5">
                            <v-col class="col-12" sm="4" md="4">
                                <h6 class="blue-lcd mb-1">No. Jemaat<strong style="color:red;">*</strong><span></span></h6>
                                <v-text-field
                                    solo
                                    dense
                                    
                                    label="Nomor Jemaat"
                                    persistent-hint
                                    v-model="no_jemaat"
                                    class="ma-0 pa-0 border-12"
                                    hide-details=true
                                >
                                </v-text-field>
                            </v-col>
                            <v-col class="col-12" sm="8" md="8">
                                <h6 class="blue-lcd mb-1">Name<strong style="color:red;">*</strong><span></span></h6>
                                <v-text-field
                                    solo
                                    dense
                                    
                                    label="Name"
                                    persistent-hint
                                    v-model="nama"
                                    class="ma-0 pa-0 border-12"
                                    hide-details=true
                                >
                                </v-text-field>
                            </v-col>
                            <v-col class="col-12" sm="12" md="12">
                                <h6 class="blue-lcd mb-1">Address 1<span></span></h6>
                                <v-text-field
                                    solo
                                    dense
                                    
                                    label="Address 1"
                                    persistent-hint
                                    v-model="address_1"
                                    class="ma-0 pa-0 border-12"
                                    hide-details=true
                                >
                                </v-text-field>
                            </v-col>
                            <v-col class="col-12" sm="12" md="12">
                                <h6 class="blue-lcd mb-1">Address 2<span></span></h6>
                                <v-text-field
                                    solo
                                    dense
                                    label="Address 2"
                                    persistent-hint
                                    v-model="address_2"
                                    class="ma-0 pa-0 border-12"
                                    hide-details=true
                                >
                                </v-text-field>
                            </v-col>
                            <v-col class="col-12" sm="5" md="5">
                                <h6 class="blue-lcd mb-1">City<span></span></h6>
                                <v-text-field
                                    solo
                                    dense
                                    label="City"
                                    persistent-hint
                                    v-model="city"
                                    class="ma-0 pa-0 border-12"
                                    hide-details=true
                                >
                                </v-text-field>
                            </v-col>
                            <v-col class="col-12" sm="5" md="5">
                                <h6 class="blue-lcd mb-1">Country<span class="red--text"></span></h6>
                                <v-text-field
                                    solo
                                    dense
                                    label="Country"
                                    persistent-hint
                                    v-model="country"
                                    class="ma-0 pa-0 border-12"
                                    hide-details=true
                                >
                                </v-text-field>
                            </v-col>
                            <v-col class="col-12" sm="2" md="2">
                                <h6 class="blue-lcd mb-1">Status</h6>
                                <v-switch
                                    inset
                                    v-model="active_flag"
                                ></v-switch>
                            </v-col>
                        </v-row>
                        <small class="mt-1 pt-1">*indicates required field</small>

                    </v-container>
                </v-card-text>
                <v-card-actions class="text-center">
                    <v-row justify="center">
                        <v-col cols="12" xs="12" sm="3" md="3">
                            <v-btn block class="rounded-l mr-2" color="#fff" @click="[dialog = false, showDetail = false, clear()]">Cancel</v-btn>
                        </v-col>
                        <v-col cols="12" xs="12" sm="3" md="3" v-if="showDetail == false">
                            <v-btn block class="rounded-l text-white" color="success" @click="submit()">Save</v-btn>
                        </v-col>
                    </v-row>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog
            v-model="dialogedit"
            persistent
            max-width="600px"
        >
            <v-card class="p-2 rounded-l">
                <v-card-title class="text-center">
                    <v-row>
                        <v-col cols="12">
                        Edit Data Jemaat
                        </v-col>
                        <v-divider></v-divider>
                    </v-row>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row class="mt-2 mb-5">
                            <v-col class="col-12" sm="4" md="4">
                                <h6 class="blue-lcd mb-1">No. Jemaat <strong style="color:red;">*</strong><span></span></h6>
                                <v-text-field
                                    solo
                                    dense
                                    
                                    label="No. Jemaat"
                                    persistent-hint
                                    v-model="no_jemaat"
                                    class="ma-0 pa-0 border-12"
                                    hide-details=true
                                >
                                </v-text-field>
                            </v-col>
                            <v-col class="col-12" sm="8" md="8">
                                <h6 class="blue-lcd mb-1">Name<strong style="color:red;">*</strong><span></span></h6>
                                <v-text-field
                                    solo
                                    dense
                                    label="Name"
                                    persistent-hint
                                    v-model="nama"
                                    class="ma-0 pa-0 border-12"
                                    hide-details=true
                                >
                                </v-text-field>
                            </v-col>
                            <v-col class="col-12" sm="12" md="12">
                             <h6 class="blue-lcd mb-1">Address 1<span></span></h6>
                             <v-text-field
                                    solo
                                    dense
                                    label="Address 1"
                                    persistent-hint
                                    v-model="address_1"
                                    class="ma-0 pa-0 border-12"
                                    hide-details=true
                                >
                                </v-text-field>
                            </v-col>
                            <v-col class="col-12" sm="12" md="12">
                                <h6 class="blue-lcd mb-1">Address 2<span></span></h6>
                                <v-text-field
                                    solo
                                    dense
                                    label="Address 2"
                                    persistent-hint
                                    v-model="address_2"
                                    class="ma-0 pa-0 border-12"
                                    hide-details=true
                                >
                                </v-text-field>
                            </v-col>
                            <v-col class="col-12" sm="5" md="5">
                                <h6 class="blue-lcd mb-1">City<span></span></h6>
                               <v-text-field
                                    solo
                                    dense
                                    label="City"
                                    persistent-hint
                                    v-model="city"
                                    class="ma-0 pa-0 border-12"
                                    hide-details=true
                                >
                                </v-text-field>
                            </v-col>
                                <v-col class="col-12" sm="5" md="5">
                                <h6 class="blue-lcd mb-1">Country<span class="red--text"></span></h6>
                                <v-text-field
                                    solo
                                    dense
                                    label="Country"
                                    persistent-hint
                                    v-model="country"
                                    class="ma-0 pa-0 border-12"
                                    hide-details=true
                                >
                                </v-text-field>
                            </v-col>
                            <v-col class="col-12" sm="2" md="2">
                                <h6 class="blue-lcd mb-1">Status</h6>
                                <v-switch
                                    inset
                                    v-model="active_flag"
                                ></v-switch>
                            </v-col>
                        </v-row>
                        <small class="mt-1 pt-1">*indicates required field</small>

                    </v-container>
                </v-card-text>
                <v-card-actions class="text-center">
                    <v-row justify="center">
                        <v-col cols="12" xs="12" sm="3" md="3">
                            <v-btn block class="rounded-l mr-2" color="#fff" @click="[dialogedit = false, clear()]">Cancel</v-btn>
                        </v-col>
                        <v-col cols="12" xs="12" sm="3" md="3">
                            <v-btn block class="rounded-l text-white" color="success" @click="submitEdit()">Save</v-btn>
                        </v-col>
                    </v-row>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog
            v-model="dialogfilter"
            persistent
            max-width="600px"
        >
            <v-card class="p-2 rounded-l">
                <v-card-title class="text-center">
                    <v-row>
                        <v-col cols="12">
                            Filter Jemaat
                        </v-col>
                        <v-divider></v-divider>
                    </v-row>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row class="mt-2 mb-5">
                            <v-col class="col-12" sm="12" md="6">
                                <h6 class="blue-lcd mb-1">Entity ID </h6>
                                <v-autocomplete
                                    dense
                                    solo
                                    clearable
                                    v-model="entity_id"
                                    :items="entities"
                                    item-value="entity_id"
                                    item-text="description"
                                    class="ma-0 pa-0 border-12"
                                    hide-details=true
                                    @change="(event) => getBranchOffice(event)"
                                ></v-autocomplete> 
                            </v-col>
                            <v-col class="col-12" sm="12" md="4">
                                <h6 class="blue-lcd mb-1">Office ID </h6>
                                <v-autocomplete
                                    dense
                                    solo
                                    clearable
                                    v-model="office_id"
                                    :items="offices"
                                    item-value="office_id"
                                    item-text="office"
                                    class="ma-0 pa-0 border-12"
                                    hide-details=true
                                    @change="(event) => Perent(event)"
                                ></v-autocomplete> 
                            </v-col>
                            <v-col class="col-12" sm="2" md="2">
                                <h6 class="blue-lcd mb-1">Status</h6>
                                <v-switch
                                    inset
                                    v-model="status"
                                ></v-switch>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions class="text-center">
                    <v-row justify="center">
                        <v-col cols="12" xs="12" sm="3" md="3">
                            <v-btn block class="rounded-l mr-2" color="#fff" @click="[clear(),dialogfilter = false]">Cancel</v-btn>
                        </v-col>
                        <v-col cols="12" xs="12" sm="3" md="3">
                            <v-btn block class="rounded-l text-white" color="#20bf6b" @click="[getSearch(), dialogfilter = false]">Filter</v-btn>
                        </v-col>
                    </v-row>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog
            v-model="dialogdetail"
            persistent
            max-width="600px"
        >
            <v-card class="p-2 rounded-l">
                <v-card-title class="text-center">
                    <v-row>
                        <v-col cols="12">
                            Detail Data Jemaat
                        </v-col>
                        <v-divider></v-divider>
                    </v-row>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row class="mt-2 mb-5">
                            <v-col class="col-12" sm="4" md="4">
                                <h6 class="blue-lcd mb-1">No. Jemaat<span></span></h6>
                                <v-text-field
                                    solo
                                    dense
                                    
                                    label="Nomor Jemaat"
                                    persistent-hint
                                    v-model="no_jemaat"
                                    class="ma-0 pa-0 border-12"
                                    hide-details=true
                                >
                                </v-text-field>
                            </v-col>
                            <v-col class="col-12" sm="8" md="8">
                                <h6 class="blue-lcd mb-1">Name<span></span></h6>
                                <v-text-field
                                    solo
                                    dense
                                    
                                    label="Name"
                                    persistent-hint
                                    v-model="nama"
                                    class="ma-0 pa-0 border-12"
                                    hide-details=true
                                >
                                </v-text-field>
                            </v-col>
                            <v-col class="col-12" sm="12" md="12">
                                <h6 class="blue-lcd mb-1">Address 1<span></span></h6>
                                <v-text-field
                                    solo
                                    dense
                                    
                                    label="Address 1"
                                    persistent-hint
                                    v-model="address_1"
                                    class="ma-0 pa-0 border-12"
                                    hide-details=true
                                >
                                </v-text-field>
                            </v-col>
                            <v-col class="col-12" sm="12" md="12">
                                <h6 class="blue-lcd mb-1">Address 2<span></span></h6>
                                <v-text-field
                                    solo
                                    dense
                                    label="Address 2"
                                    persistent-hint
                                    v-model="address_2"
                                    class="ma-0 pa-0 border-12"
                                    hide-details=true
                                >
                                </v-text-field>
                            </v-col>
                            <v-col class="col-12" sm="5" md="5">
                                <h6 class="blue-lcd mb-1">City<span></span></h6>
                                <v-text-field
                                    solo
                                    dense
                                    label="City"
                                    persistent-hint
                                    v-model="city"
                                    class="ma-0 pa-0 border-12"
                                    hide-details=true
                                >
                                </v-text-field>
                            </v-col>
                            <v-col class="col-12" sm="5" md="5">
                                <h6 class="blue-lcd mb-1">Country<span class="red--text"></span></h6>
                                <v-text-field
                                    solo
                                    dense
                                    label="Country"
                                    persistent-hint
                                    v-model="country"
                                    class="ma-0 pa-0 border-12"
                                    hide-details=true
                                >
                                </v-text-field>
                            </v-col>
                            <v-col class="col-12" sm="2" md="2">
                                <h6 class="blue-lcd mb-1">Status</h6>
                                <v-switch
                                    inset
                                    v-model="active_flag"
                                ></v-switch>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions class="text-center">
                    <v-row justify="center">
                        <v-col cols="12" xs="12" sm="3" md="3">
                            <v-btn block class="rounded-l mr-2" color="#fff" @click="[dialogdetail = false, showDetail = false, clear()]">Cancel</v-btn>
                        </v-col>
                    </v-row>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</v-container>
</template>
<script>


import {data_jemaat} from "@/backend-api/jemaat/index"
import Notification from "@/components/Notification.vue"
import Breadcrumb from "@/components/Breadcrumb.vue"
import {entity} from "@/backend-api/entity/master"
import {office} from "@/backend-api/office/index"
import {ledger_parent} from "@/backend-api/ledger/coa/ledger_parent"

export default {
    components: {
        Notification, Breadcrumb
    },
    data() {
        return {
            //tab: 'tab-1',
            loading: false,
            snackbar: {
                color: null,
                icon: null,
                mode: null,
                position: "top",
                text: null,
                timeout: 7500,
                title: null,
                visible: false
            },
            timeout: 7500,
            search: '',
            text_dialog:'',
            dialog: false,
            breadcumbs: [
                {
                    text: 'Finance',
                    disabled: false,
                    href: '/admin/finance',
                },
                {
                    text: 'Jemaat',
                    disabled: true,
                }
            ],
            headers:[
                { text: 'No. Jemaat',value: 'no_jemaat'    ,align: 'start',align:'left'},
                { text: 'Nama'      , value: 'nama'        , align:'left'},
                //{ text: 'Alamat 1'  , value: 'address_1'   , align:'left'},
                //{ text: 'Alamat 2'  , value: 'address_2'   , align:'left'},
                { text: 'Kota'      , value: 'city'        , align:'left'},
                { text: 'Provinsi'  , value: 'country'     , align:'left'},
                { text: 'Status'    , value: 'active_flag' , align:'left'},
                { text: 'Action'    , value: 'action'      , align:'left'},
            ],
            headersColumn: {              
                'No. Jemaat': 'no_jemaat',
                'Nama': 'nama',
                'Alamat 1': 'address_1',
                'Alamat 2': 'address_2',
                'Kota': 'city',
                'Status': 'active_flag',
                'Provinsi': 'country',
            },
            menu_headers: [],
            perents:[],
            entities: [],
            offices: [],
            showDetail: false,
            dialogedit: false,
            id:'',
            entity_id: '',
            office_id:'',
            no_jemaat: '',
            nama:'',
            
            address_1:'',
            address_2: '',
            city:'',
            country:'',
            active_flags : [
                {
                    value_af: 'N',
                    name_af: 'Non Active'
                },
                {
                    value_af: 'Y',
                    name_af: 'Active'
                }
            ],
            active_flag: true,
            dialogfilter: false,
        }
    },
    async mounted(){
        this.$store.dispatch('setOverlay', true)
        await this.getPullData()
        this.getPullData()
        await this.getEntity()
        this.$store.dispatch('setOverlay', false)
    },
    methods: {
        tr_datatable(item) {
            const rowClass = 'tr_datatable'
            return rowClass;
        },
        clear(){
            this.id = ''
            this.office_id = ''
            this.entity_id = ''
            this.no_jemaat = ''
            this.nama = ''
            this.address_1 = ''
            this.address_2 = ''
            this.city = ''
            this.country = ''
            this.active_flag = ''
            this.showDetail = false
        },
        detailItem(item){
            this.detail = item
            this.dialogdetail = true
            this.showDetail = true
            this.id = item.id,
            this.office_id = item.office_id
            this.entity_id = item.entity_id
            this.no_jemaat = item.no_jemaat
            this.nama = item.nama
            this.address_1 = item.address_1
            this.address_2 = item.address_2
            this.active_flag = item.active_flag === 'Y' ? true : false
            this.city = item.city
            this.country = item.country
        },
        detailItemEdit(item){
            console.log(item)
            this.dialogedit = true
            this.id = item.id,
            this.office_id = item.office_id
            this.entity_id = item.entity_id
            this.no_jemaat = item.no_jemaat
            this.nama = item.nama
            this.address_1 = item.address_1
            this.address_2 = item.address_2
            this.active_flag = item.active_flag === 'Y' ? true : false
            this.city = item.city
            this.country = item.country
        },
        
        async getSearch(){
            this.data_jemaat = []
            this.$store.dispatch('setOverlay', true)

            var respData = await data_jemaat.fetchDataJemaat(`?search=${this.search ? this.search : ''}&entity_id=${this.entity_id ? this.entity_id : ''}&office_id=${this.office_id ? this.office_id : ''}&active_flag=${this.status === true ? 'Y' : 'N'}`, null, false, false, false)
            if (respData.status === 200) {
                console.log(respData)
                this.data_jemaat = respData.data.data
                this.$store.dispatch('setOverlay', false)
                this.clear()
            } else{
               this.$store.dispatch('setOverlay', false)
            }
        },
        async getPullData(){
            this.data_jemaat = []
            this.$store.dispatch('setOverlay', true)
            
            //var respData = await data_jemaat.fetchDataJemaat(`?search=${this.search ? this.search : ''}&no_jemaat=${this.no_jemaat ? this.no_jemaat : ''}&nama=${this.nama ? this.nama : ''}&address_1=${this.address_1s ? this.address_1s : ''}&city=${this.citys ? this.citys.city : ''}&country=${this.country ? this.country : ''}`, null, false, false, false)
            //var respData = await data_jemaat.fetchDataJemaat(`?search=${this.search ? this.search : ''}`, null, false, false, false)
            var respData = await data_jemaat.fetchDataJemaat(``, null, false, false, false)
            if (respData.status === 200) {
                console.log(respData)
                this.data_jemaat = respData.data.data
                this.$store.dispatch('setOverlay', false)
                this.clear()
            } else{
               this.$store.dispatch('setOverlay', false)
            }
        },
        

        async submit(){
           // console.log(this.ac_nums.ac_num)
            this.$store.dispatch('setOverlay', true)
            this.dialog = false

            if (this.no_jemaat == '' || this.nama == '') {
                this.snackbar = {
                    color: "error",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Error",
                    text: 'Please fill your fileds and submit for save',
                    visible: true
                };
                this.$store.dispatch('setOverlay', false)
                this.dialog = true

                return false
            }

            var reqBody = {
                'no_jemaat'   : this.no_jemaat ,
                'nama'        : this.nama ,
                'active_flag' : this.active_flag ,
                'address_1'   : this.address_1 ,
                'address_2'   : this.address_2 ,
                'city'        : this.city ,
                'country'     : this.country,
                'active_flag': this.active_flag === true ? 'Y' : 'N'
            }

            const respData = await data_jemaat.storeDataJemaat('', reqBody, false, false, false)

            if (respData.status === 200) {
                this.snackbar = {
                    color: "success",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Success",
                    text: 'Successfully Create Data',
                    visible: true
                };

                this.$store.dispatch('setOverlay', false)
                this.dialog = false
                this.clear()
                this.getPullData()

            } else{
                this.$store.dispatch('setOverlay', false)
                this.dialog = true

                this.snackbar = {
                    color: "error",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Error",
                    text: respData.data,
                    visible: true
                };
            }
        },
        async submitEdit(){
            this.$store.dispatch('setOverlay', true)
            this.dialogedit = false

            if  (this.no_jemaat == '' || this.nama == '' ) {
                this.snackbar = {
                    color: "error",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Error",
                    text: 'Please fill your fileds and submit for save',
                    visible: true
                };
                this.$store.dispatch('setOverlay', false)
                this.dialogEdit = true

                return false
            }
            
            var reqBody = {
                'id'          : this.id,
                'no_jemaat'   : this.no_jemaat ,
                'nama'        : this.nama ,
                'active_flag' : this.active_flag ,
                'address_1'   : this.address_1 ,
                'address_2'   : this.address_2 ,
                'city'        : this.city ,
                'country'     : this.country,
                'active_flag': this.active_flag === true ? 'Y' : 'N'
            }

            const respData = await data_jemaat.updateDataJemaat('', reqBody, false, false, false)

            if (respData.status === 200) {
                this.snackbar = {
                    color: "success",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Success",
                    text: 'Successfully Update Data',
                    visible: true
                };

                this.$store.dispatch('setOverlay', false)
                this.dialogEdit = false
                this.clear()
                this.getPullData()

            } else{
                this.$store.dispatch('setOverlay', false)
                this.dialogEdit = true

                this.snackbar = {
                    color: "error",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Error",
                    text: respData.data,
                    visible: true
                };
            }
        },
        
        async updateStatusMenuJemaat(item){
            this.$store.dispatch('setOverlay', true)
            var reqBody = {
                'id'          : item.id,
                'no_jemaat'   : item.no_jemaat ,
                'nama'        : item.nama ,
                'active_flag' : item.active_flag
            }
            const respData = await data_jemaat.updateMenuHeaderStatus(``, reqBody, false, false, false)
            if (respData.status === 200) {
                this.snackbar = {
                    color: "success",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Success",
                    text: 'Successfully Update Status',
                    visible: true
                };
                this.$store.dispatch('setOverlay', false)
                this.getPullData()
            } else{
                this.$store.dispatch('setOverlay', false)
                this.snackbar = {
                    color: "error",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Error",
                    text: respData.data,
                    visible: true
                };
            }
        },

        async getEntity(){
            var respData = await entity.fetchEntity("", null, false, false, false)
            if (respData.status === 200) {
                this.entities = respData.data
            }
        },
        async getBranchOffice(event){
            if (event === null || event === '') {
                this.office_id = ''
            } else{
                var respData = await office.fetchOffice(`?entity_id=${this.entity_id ? this.entity_id : ''}`, null, false, false, false)
                if (respData.status === 200) {
                    this.offices = respData.data.data
                }
            }
        },
        async Perent(event){
            if (event === null || event === '') {
                this.ac_pars = ''
            } else{
                var respData = await ledger_parent.fetchLedgerParent(`?entity_id=${this.entity_id ? this.entity_id : ''}&office_id=${this.office_id ? this.office_id : ''}`, null, false, false, false)
                if (respData.status === 200) {
                this.perents = respData.data.data
                }
            }
        },

        async exportExcel(){
            //var respData = await ledger_finance.fetchLedgerFinance(`?search=${this.search ? this.search : ''}&entity_id=${this.entity_id ? this.entity_id : ''}&office_id=${this.office_id ? this.office_id : ''}&ac_par=${this.ac_pars ? this.ac_pars : ''}&ac_num=${this.ac_nums ? this.ac_nums.ac_num : ''}`, null, false, false, false)
            var respData = await data_jemaat.fetchDataJemaat(``, null, false, false, false)
            if (respData.status === 200) {
                return respData.data.data
            }
        },
        startDownload(){
            this.$store.dispatch('setOverlay', true)
        },
        finishDownload(){
            this.$store.dispatch('setOverlay', false)
        },
    }
}
</script>
  
<style>
.v-card--reveal {
    align-items: center;
    bottom: 0;
    justify-content: center;
    opacity: .9;
    position: absolute;
    width: 100%;
}
.tr_datatable{
  background-color: #F5F7F8 !important;
}
</style>